import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { Package, AlertTriangle, ShoppingCart, Plus, Search } from 'lucide-react';
import Sidebar from '../components/sidebar';

const Sales = () => {
    const [salesData, setSalesData] = useState({
        totalSales: 0,
        itemsSold: 0,
        recentItems: []
    });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulating data fetch
        setTimeout(() => {
            setSalesData({
                totalSales: 1234.56,
                itemsSold: 42,
                recentItems: [
                    { orderNumber: '001', itemName: 'Product A', amount: 25.99 },
                    { orderNumber: '002', itemName: 'Product B', amount: 15.50 },
                    { orderNumber: '003', itemName: 'Product C', amount: 35.00 },
                    { orderNumber: '004', itemName: 'Product D', amount: 10.99 },
                    { orderNumber: '005', itemName: 'Product E', amount: 50.00 },
                ]
            });
            setIsLoading(false);
        }, 1000);
    }, []);

    return (
        <div className="flex bg-white">
            <Sidebar />
            <div className="flex-1 p-8 transition-all duration-300">
                <h1 className="text-5xl font-semibold my-10">Sales</h1>
                <div className="space-y-8">
                    <div className="max-w-full xl:max-w-5xl">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4 mb-8">
                            <StatCard
                                title="Total Sales"
                                value={`GHs ${salesData.totalSales.toFixed(2)}`}
                                subtitle="All time total revenue"
                            />
                            <StatCard
                                title="Sales Today"
                                value={`GHs ${salesData.totalSales.toFixed(2)}`}
                                subtitle="Total revenue"
                            />
                            <StatCard
                                title="Items Sold Today"
                                value={salesData.itemsSold}
                                subtitle="Units sold"
                            />
                        </div>
                    </div>
                </div>
                <div className="mb-4 flex justify-between items-center">
                    <h2 className="text-xl font-semibold">Recent Sales</h2>
                    <div className="flex space-x-2">
                        <div className="relative">
                            <input
                                type="text"
                                placeholder="Search sales..."
                                className="pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
                                <Search className="h-5 w-5 text-gray-400" />
                            </div>
                        </div>
                        <button className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-blue-600 transition-colors">
                            <Plus className="h-5 w-5 mr-2" />
                            Add New Sale
                        </button>
                    </div>
                </div>
                <div className="bg-white shadow rounded-lg overflow-hidden">
                    <table className="w-full">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Order #</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {isLoading ? (
                                <tr>
                                    <td colSpan="3" className="px-6 py-4 text-center">Loading...</td>
                                </tr>
                            ) : salesData.recentItems.length === 0 ? (
                                <tr>
                                    <td colSpan="3" className="px-6 py-4 text-center">No sales recorded</td>
                                </tr>
                            ) : (
                                salesData.recentItems.map((item, index) => (
                                    <SaleItem key={index} item={item} />
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};


const StatCard = ({ title, value, subtitle }) => (
    <div className="bg-white p-4">
        <h3 className="text-sm text-gray-500 mb-4 flex items-center">
            {title}
            <span className="ml-1 text-gray-400 cursor-help" title="Info about this metric">ⓘ</span>
        </h3>
        <p className="text-3xl font-bold mb-2">{value}</p>
        {subtitle && <p className="text-xs text-gray-500">{subtitle}</p>}
    </div>
);

const SaleItem = ({ item }) => (
    <tr>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Order #{item.orderNumber}</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.itemName}</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">GHs {item.amount.toFixed(2)}</td>
    </tr>
);

export default Sales;