import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { Package, AlertTriangle, ShoppingCart, Plus, Search } from 'lucide-react';
import Sidebar from '../components/sidebar';

const Inventory = () => {
    const [inventoryItems, setInventoryItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulating data fetch
        setTimeout(() => {
            setInventoryItems([
                { id: 1, itemName: 'Product A', quantity: 50, sellingPrice: 25.99, reorderPoint: 10 },
                { id: 2, itemName: 'Product B', quantity: 5, sellingPrice: 15.50, reorderPoint: 15 },
                { id: 3, itemName: 'Product C', quantity: 0, sellingPrice: 35.00, reorderPoint: 5 },
                { id: 4, itemName: 'Product D', quantity: 75, sellingPrice: 10.99, reorderPoint: 20 },
                { id: 5, itemName: 'Product E', quantity: 30, sellingPrice: 50.00, reorderPoint: 10 },
            ]);
            setIsLoading(false);
        }, 1000);
    }, []);

    const totalItems = inventoryItems.length;
    const lowStockItems = inventoryItems.filter(item => item.quantity <= item.reorderPoint && item.quantity > 0).length;
    const outOfStockItems = inventoryItems.filter(item => item.quantity === 0).length;

    return (
        <div className="flex h-screen bg-white overflow-hidden">
            <Sidebar />
            <div className="flex-1 flex flex-col overflow-hidden">
                <div className="flex-1 overflow-y-auto p-8">
                    <h1 className="text-5xl font-semibold my-10">Inventory</h1>
                    <div className="space-y-8">
                        <div className="max-w-full xl:max-w-5xl">
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4 mb-8">
                                <StatCard title="Total Items" value={totalItems} subtitle="In inventory" icon={<Package className="h-6 w-6" />} />
                                <StatCard title="Low Stock" value={lowStockItems} subtitle="Below threshold" icon={<AlertTriangle className="h-6 w-6" />} />
                                <StatCard title="Out of Stock" value={outOfStockItems} subtitle="Need reorder" icon={<ShoppingCart className="h-6 w-6" />} />
                                <StatCard title="Total Value" value={`$${inventoryItems.reduce((sum, item) => sum + item.quantity * item.sellingPrice, 0).toFixed(2)}`} subtitle="Inventory worth" icon={<Package className="h-6 w-6" />} />
                                <StatCard title="Potential Profit" value={`$${inventoryItems.reduce((sum, item) => sum + item.quantity * item.sellingPrice, 0).toFixed(2)}`} subtitle="Inventory worth" icon={<Package className="h-6 w-6" />} />
                            </div>
                        </div>
                    </div>
                    <div className="mb-4 flex justify-between items-center">
                        <h2 className="text-xl font-semibold">Inventory List</h2>
                        <div className="flex space-x-2">
                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder="Search inventory..."
                                    className="pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                                <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
                                    <Search className="h-5 w-5 text-gray-400" />
                                </div>
                            </div>
                            <button className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-blue-600 transition-colors">
                                <Plus className="h-5 w-5 mr-2" />
                                Add New Item
                            </button>
                        </div>
                    </div>
                    <div className="bg-white shadow rounded-lg overflow-hidden">
                        <table className="w-full">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Quantity</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {isLoading ? (
                                    <tr>
                                        <td colSpan="4" className="px-6 py-4 text-center">Loading...</td>
                                    </tr>
                                ) : inventoryItems.length === 0 ? (
                                    <tr>
                                        <td colSpan="4" className="px-6 py-4 text-center">No products added</td>
                                    </tr>
                                ) : (
                                    inventoryItems.map((item) => (
                                        <InventoryItem key={item.id} item={item} />
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

const StatCard = ({ title, value, subtitle }) => (
    <div className="bg-white p-4">
        <h3 className="text-sm text-gray-500 mb-4 flex items-center">
            {title}
            <span className="ml-1 text-gray-400 cursor-help" title="Info about this metric">ⓘ</span>
        </h3>
        <p className="text-3xl font-bold mb-2">{value}</p>
        {subtitle && <p className="text-xs text-gray-500">{subtitle}</p>}
    </div>
);

const InventoryItem = ({ item }) => {
    let statusColor = "bg-green-100 text-green-800";
    let status = "In Stock";
    if (item.quantity <= item.reorderPoint && item.quantity > 0) {
        statusColor = "bg-yellow-100 text-yellow-800";
        status = "Low Stock";
    } else if (item.quantity === 0) {
        statusColor = "bg-red-100 text-red-800";
        status = "Out of Stock";
    }

    return (
        <tr>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{item.itemName}</td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.quantity}</td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">${item.sellingPrice.toFixed(2)}</td>
            <td className="px-6 py-4 whitespace-nowrap">
                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${statusColor}`}>
                    {status}
                </span>
            </td>
        </tr>
    );
};

export default Inventory;