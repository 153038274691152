import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Home, BarChart2, Package, ShoppingCart, DollarSign, Settings, Users, ChevronLeft, ChevronRight, Plus, LogOut } from 'lucide-react';
import { auth } from '../../../firebase-config'; // Make sure this path is correct
import { signOut } from 'firebase/auth';

const Sidebar = () => {
    const [isCollapsed, setIsCollapsed] = useState(() => {
        // Initialize state from localStorage, default to false if not set
        return localStorage.getItem('sidebarCollapsed') === 'true';
    });
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // Update localStorage when isCollapsed changes
        localStorage.setItem('sidebarCollapsed', isCollapsed);
    }, [isCollapsed]);

    const toggleSidebar = () => {
        setIsCollapsed(prevState => !prevState);
    };

    const handleNewSale = () => {
        navigate('/sales/add');
    };

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate('/login'); // Redirect to login page after logout
        } catch (error) {
            console.error("Error signing out: ", error);
        }
    };

    return (
        <div className={`bg-black text-white ${isCollapsed ? 'w-16' : 'w-64'} min-h-screen p-4 transition-all duration-300 relative flex flex-col`}>
            <div className={`flex items-center mb-8 ${isCollapsed ? 'justify-center' : ''}`}>
                <img src="/api/placeholder/40/40" alt="Logo" className={`${isCollapsed ? 'mr-0' : 'mr-2'}`} />
                {!isCollapsed && <span className="font-semibold">SalesTrack Pro</span>}
            </div>
            <button
                onClick={handleNewSale}
                className={`bg-white text-black w-full py-2 rounded mb-6 flex items-center justify-center ${isCollapsed ? 'p-2' : ''}`}
            >
                {isCollapsed ? <Plus size={20} /> : "New Sale"}
            </button>
            <nav className="flex-grow">
                <SidebarLink to="/home" icon={<Home size={20} />} text="Home" isCollapsed={isCollapsed} isActive={location.pathname === '/home'} />
                <SidebarLink to="/reports" icon={<BarChart2 size={20} />} text="Reports" isCollapsed={isCollapsed} isActive={location.pathname === '/reports'} />
                <SidebarLink to="/sales" icon={<ShoppingCart size={20} />} text="Sales" isCollapsed={isCollapsed} isActive={location.pathname === '/sales'} />
                <SidebarLink to="/inventory" icon={<Package size={20} />} text="Inventory" isCollapsed={isCollapsed} isActive={location.pathname === '/inventory'} />
                <SidebarLink to="/customers" icon={<Users size={20} />} text="Customers" isCollapsed={isCollapsed} isActive={location.pathname === '/customers'} />
                <SidebarLink to="/finance" icon={<DollarSign size={20} />} text="Finance" isCollapsed={isCollapsed} isActive={location.pathname === '/finance'} />
                <SidebarLink to="/settings" icon={<Settings size={20} />} text="Settings" isCollapsed={isCollapsed} isActive={location.pathname === '/settings'} />
            </nav>
            <button
                onClick={handleLogout}
                className={`mt-auto flex items-center py-2 px-4 rounded hover:bg-gray-800 ${isCollapsed ? 'justify-center' : ''}`}
                title={isCollapsed ? "Logout" : ''}
            >
                <span className="text-white"><LogOut size={20} /></span>
                {!isCollapsed && <span className="ml-3 text-white">Logout</span>}
            </button>
            <button
                onClick={toggleSidebar}
                className="absolute -right-3 top-1/2 bg-black text-white p-1 rounded-full"
            >
                {isCollapsed ? <ChevronRight size={20} /> : <ChevronLeft size={20} />}
            </button>
        </div>
    );
};

const SidebarLink = ({ to, icon, text, isActive, isCollapsed }) => (
    <Link
        to={to}
        className={`flex items-center py-2 px-4 rounded mb-1 ${isActive ? 'bg-gray-800' : 'hover:bg-gray-800'
            } ${isCollapsed ? 'justify-center' : ''}`}
        title={isCollapsed ? text : ''}
    >
        <span className="text-white">{icon}</span>
        {!isCollapsed && <span className="ml-3 text-white">{text}</span>}
    </Link>
);

export default Sidebar;