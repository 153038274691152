import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search, Camera, ShoppingCart, Plus, Minus, X, Grid, List } from 'lucide-react';

const AddSale = () => {
    const navigate = useNavigate();
    const [saleItems, setSaleItems] = useState([]);
    const [inventoryItems, setInventoryItems] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [totalCost, setTotalCost] = useState(0);
    const [isGridView, setIsGridView] = useState(true);

    useEffect(() => {
        // Simulating fetching inventory data
        setInventoryItems([
            { id: 1, name: 'Product A', price: 10.99, stock: 50 },
            { id: 2, name: 'Product B', price: 15.99, stock: 30 },
            { id: 3, name: 'Product C', price: 5.99, stock: 100 },
            // Add more items as needed
        ]);
    }, []);

    useEffect(() => {
        calculateTotal();
    }, [saleItems]);

    const calculateTotal = () => {
        const total = saleItems.reduce((sum, item) => sum + (item.price * item.quantity), 0);
        setTotalCost(total);
    };

    const addToSale = (item) => {
        const existingItem = saleItems.find(saleItem => saleItem.id === item.id);
        if (existingItem) {
            setSaleItems(saleItems.map(saleItem =>
                saleItem.id === item.id
                    ? { ...saleItem, quantity: saleItem.quantity + 1 }
                    : saleItem
            ));
        } else {
            setSaleItems([...saleItems, { ...item, quantity: 1 }]);
        }
    };

    const removeFromSale = (itemId) => {
        setSaleItems(saleItems.filter(item => item.id !== itemId));
    };

    const updateQuantity = (itemId, newQuantity) => {
        setSaleItems(saleItems.map(item =>
            item.id === itemId ? { ...item, quantity: Math.max(0, newQuantity) } : item
        ).filter(item => item.quantity > 0));
    };

    const handleSearch = () => {
        // Implement search functionality here
        console.log("Searching for:", searchQuery);
    };

    const handleClose = () => {
        navigate(-1); // This will navigate back to the previous page
    };


    const renderInventoryItem = (item) => (
        <div
            key={item.id}
            className={`p-4 bg-gray-100 rounded border border-gray-300 cursor-pointer transition-colors duration-200 hover:bg-gray-200 ${isGridView ? '' : 'flex items-center'}`}
            onClick={() => addToSale(item)}
        >
            <div className={`${isGridView ? 'w-full' : 'w-20'} h-20 ${isGridView ? 'mb-4' : 'mr-4'} bg-gray-200 rounded flex items-center justify-center overflow-hidden`}>
                {item.image ? (
                    <img src={item.image} alt={item.name} className="w-full h-full object-cover" />
                ) : (
                    <Camera size={32} className="text-gray-400" />
                )}
            </div>
            <div>
                <h3 className="font-bold text-lg mb-1">{item.name}</h3>
                <p className="text-gray-700">GHS {item.price.toFixed(2)}</p>
                <p className="text-gray-700">{item.stock} left</p>
            </div>
        </div>
    );

    return (
        <div className="bg-white relative">
            <button
                onClick={handleClose}
                className="absolute top-0 right-4 p-2 rounded-full hover:bg-gray-200 transition-colors duration-200"
                aria-label="Close"
            >
                <X size={24} />
            </button>

            <div className="w-full border-b border-gray-200">
                <h1 className="text-3xl font-semibold my-7 text-center">Add New Sale</h1>
            </div>

            <div className="max-w-7xl mx-auto p-4 pt-10 bg-white">
                <div className="flex gap-8">
                    {/* Left Column */}
                    <div className="w-2/3">
                        <div className="overflow-y-auto">
                            <div className="flex justify-between items-center mb-4">
                                <h2 className="text-2xl font-bold">Inventory</h2>
                            </div>
                            <div className="mb-4 relative flex items-center">
                                <Search size={24} className="absolute left-4 text-gray-400" />
                                <input
                                    type="text"
                                    placeholder="Search inventory..."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    className="flex-grow p-4 pl-12 pr-24 border rounded-lg border-gray-300"
                                />
                                <button
                                    onClick={handleSearch}
                                    className="absolute right-0 px-6 py-2.5 m-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                                >
                                    Search
                                </button>
                            </div>
                            <div className="flex justify-between items-center mb-4">
                                <p className="text-sm text-gray-600">
                                    Click on an item to add it to the sale.
                                </p>
                                <div className="flex border border-gray-300 rounded-md">
                                    <button
                                        onClick={() => setIsGridView(true)}
                                        className={`p-1 ${isGridView ? 'bg-gray-200' : 'bg-white'} hover:bg-gray-100 transition-colors duration-200`}
                                    >
                                        <Grid size={16} className="text-gray-600" />
                                    </button>
                                    <button
                                        onClick={() => setIsGridView(false)}
                                        className={`p-1 ${!isGridView ? 'bg-gray-200' : 'bg-white'} hover:bg-gray-100 transition-colors duration-200`}
                                    >
                                        <List size={16} className="text-gray-600" />
                                    </button>
                                </div>
                            </div>
                            <div className={isGridView ? "grid grid-cols-3 gap-4" : "flex flex-col gap-4"}>
                                {inventoryItems.map(renderInventoryItem)}
                            </div>
                        </div>
                    </div>

                    {/* Right Column */}
                    <div className="w-1/2">
                        <h2 className="text-2xl font-bold mb-4">Current Sale</h2>
                        {/* <p className="text-sm text-gray-600 mb-4">
                            Scan the barcode on the item to add it to the sale.
                        </p> */}
                        <div className="border-2 border-dashed border-gray-300 rounded-lg p-7 text-center h-[calc(100vh-250px)] flex flex-col">
                            <div className="flex-grow overflow-y-auto mb-4">
                                {saleItems.length === 0 ? (
                                    <div className="text-center text-gray-500 py-8">
                                        <ShoppingCart size={48} className="mx-auto mb-4" />
                                        <p className='text-lg'>No items added to sale yet.</p>
                                        <p className="mt-4">Add items from the inventory on the left<br />or scan with your scanner</p>
                                    </div>
                                ) : (
                                    <div>
                                        {saleItems.map(item => (
                                            <div key={item.id} className="flex items-center justify-between mb-2 p-2 bg-white rounded shadow-sm border border-gray-200">
                                                <span>{item.name}</span>
                                                <div className="flex items-center">
                                                    <button onClick={() => updateQuantity(item.id, item.quantity - 1)} className="p-1">
                                                        <Minus size={16} />
                                                    </button>
                                                    <span className="mx-2">{item.quantity}</span>
                                                    <button onClick={() => updateQuantity(item.id, item.quantity + 1)} className="p-1">
                                                        <Plus size={16} />
                                                    </button>
                                                    <span className="mx-2">GHS {(item.price * item.quantity).toFixed(2)}</span>
                                                    <button onClick={() => removeFromSale(item.id)} className="p-1 text-red-500">
                                                        <X size={16} />
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            {saleItems.length > 0 && (
                                <div className="mt-auto">
                                    <div className="text-xl font-bold mb-2">
                                        Total: GHS {totalCost.toFixed(2)}
                                    </div>
                                    <button className="w-full p-3 bg-green-500 text-white rounded-lg hover:bg-green-600">
                                        Complete Sale
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddSale;