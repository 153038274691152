import React from 'react';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import Sidebar from '../components/sidebar';

const salesData = [
    { date: '2024-10-01', sales: 5000 },
    { date: '2024-10-02', sales: 6200 },
    { date: '2024-10-03', sales: 7800 },
    { date: '2024-10-04', sales: 5600 },
    { date: '2024-10-05', sales: 7000 },
    { date: '2024-10-06', sales: 8200 },
    { date: '2024-10-07', sales: 7500 },
    // Add more data points as needed
];

const Lobby = () => {
    return (
        <div className="flex bg-white">
            <Sidebar />
            <div className="flex-1 p-8 transition-all duration-300">
                <h1 className="text-5xl font-semibold my-10">Sales & Inventory Dashboard</h1>
                <div className="flex space-x-4 mb-6">
                    <button className="font-semibold">Overview</button>
                    <button className="text-gray-500">Sales</button>
                    <button className="text-gray-500">Inventory</button>
                    <button className="text-gray-500">Customers</button>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4 mb-8">
                    <StatCard title="Total Sales" value="$47,852" subtitle="Last 7 days" />
                    <StatCard title="Average Order Value" value="$85" subtitle="Last 30 days" />
                    <StatCard title="Inventory Items" value="1,254" subtitle="In stock" />
                    <StatCard title="Low Stock Items" value="23" subtitle="Below threshold" />
                    <StatCard title="New Customers" value="57" subtitle="Last 30 days" />
                </div>
                <div className="mb-4 flex justify-between items-center">
                    <h2 className="text-xl font-semibold">Daily Sales</h2>
                    <div className="flex space-x-2">
                        <select className="border p-2 rounded">
                            <option>Last 7 days</option>
                            <option>Last 30 days</option>
                            <option>Last 90 days</option>
                        </select>
                    </div>
                </div>
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={salesData}>
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Line type="monotone" dataKey="sales" stroke="#8884d8" />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

const StatCard = ({ title, value, subtitle }) => (
    <div className="bg-white p-4 rounded shadow">
        <h3 className="text-sm text-gray-500 mb-1">{title}</h3>
        <p className="text-2xl font-bold mb-1">{value}</p>
        {subtitle && <p className="text-xs text-gray-500">{subtitle}</p>}
    </div>
);

export default Lobby;