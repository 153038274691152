import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from './app/auth/AuthContext';
import { PrivateRoute } from './app/auth/PrivateRoute';
import Home from './app/pages/Home';
import Terms from './app/pages/Terms';
import Privacy from './app/pages/Privacy';
import DeleteAccount from './app/pages/DeleteAccount';
import Inventory from './app/screens/views/Inventory';
import Lobby from './app/screens/views/Lobby';
import Reports from './app/screens/views/Reports';
import Sales from './app/screens/views/Sales';
import AddSale from './app/screens/views/AddSale';
import Login from './app/auth/Login';
import Signup from './app/auth/Signup';
import Support from './app/pages/Support';

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <div className="bg-gray-900">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/delete" element={<DeleteAccount />} />
            <Route path="/support" element={<Support />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route
              path="/home"
              element={
                <PrivateRoute>
                  <Lobby />
                </PrivateRoute>
              } />
            <Route
              path="/inventory"
              element={
                <PrivateRoute>
                  <Inventory />
                </PrivateRoute>
              } />
            <Route
              path="/reports"
              element={
                <PrivateRoute>
                  <Reports />
                </PrivateRoute>
              } />
            <Route
              path="/sales"
              element={
                <PrivateRoute>
                  <Sales />
                </PrivateRoute>
              } />
            <Route
              path="/customers"
              element={
                <PrivateRoute>
                  <Reports />
                </PrivateRoute>
              } />
            <Route
              path="/sales/add"
              element={
                <PrivateRoute>
                  <AddSale />
                </PrivateRoute>
              } />
          </Routes>
        </div>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;