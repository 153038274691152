import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip, BarChart, Bar, CartesianGrid } from 'recharts';
import Sidebar from '../components/sidebar';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
};

const salesData = [
    { date: '2024-10-01', sales: 5000, formattedDate: 'Oct 1, 2024' },
    { date: '2024-10-02', sales: 6200, formattedDate: 'Oct 2, 2024' },
    { date: '2024-10-03', sales: 7800, formattedDate: 'Oct 3, 2024' },
    { date: '2024-10-04', sales: 5600, formattedDate: 'Oct 4, 2024' },
    { date: '2024-10-05', sales: 7000, formattedDate: 'Oct 5, 2024' },
    { date: '2024-10-06', sales: 8200, formattedDate: 'Oct 6, 2024' },
    { date: '2024-10-07', sales: 7500, formattedDate: 'Oct 7, 2024' },
];

const inventoryData = [
    { category: 'Electronics', inStock: 500, lowStock: 50 },
    { category: 'Clothing', inStock: 1000, lowStock: 100 },
    { category: 'Books', inStock: 750, lowStock: 75 },
    { category: 'Home & Garden', inStock: 300, lowStock: 30 },
];

const customerData = [
    { id: 1, name: 'John Doe', totalPurchases: 1500, lastPurchase: '2024-10-05' },
    { id: 2, name: 'Jane Smith', totalPurchases: 2200, lastPurchase: '2024-10-06' },
    { id: 3, name: 'Bob Johnson', totalPurchases: 1800, lastPurchase: '2024-10-07' },
    { id: 4, name: 'Alice Brown', totalPurchases: 3000, lastPurchase: '2024-10-04' },
];

const topProductsData = [
    { name: 'Product A', sales: 1200 },
    { name: 'Product B', sales: 950 },
    { name: 'Product C', sales: 800 },
    { name: 'Product D', sales: 600 },
    { name: 'Product E', sales: 500 },
];

const latestPurchases = [
    { id: 1, item: "Wireless Headphones", price: 129.99, date: "2024-10-07" },
    { id: 2, item: "Smart Watch", price: 199.99, date: "2024-10-07" },
    { id: 3, item: "Laptop", price: 999.99, date: "2024-10-06" },
    { id: 4, item: "Smartphone", price: 699.99, date: "2024-10-06" },
    { id: 5, item: "Tablet", price: 349.99, date: "2024-10-05" },
];

const Reports = () => {
    const [selectedTab, setSelectedTab] = useState('Overview');
    const [timeRange, setTimeRange] = useState('Last 7 days');

    const renderTabContent = () => {
        switch (selectedTab) {
            case 'Overview':
                return (
                    <div className="space-y-8">
                        <div className="max-w-full xl:max-w-5xl">
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4">
                                <StatCard title="Total Sales" value="$47,852" subtitle="Last 7 days" />
                                <StatCard title="Average Order Value" value="$85" subtitle="Last 30 days" />
                                <StatCard title="Inventory Items" value="1,254" subtitle="In stock" />
                                <StatCard title="Low Stock Items" value="23" subtitle="Below threshold" />
                                <StatCard title="New Customers" value="57" subtitle="Last 30 days" />
                            </div>
                        </div>
                        <div className="w-full">
                            <SalesChart salesData={salesData} timeRange={timeRange} setTimeRange={setTimeRange} />
                        </div>
                    </div>
                );
            case 'Sales':
                return (
                    <div className="flex flex-col md:flex-row gap-8">
                        <div className="w-full md:w-1/2">
                            <SalesChart salesData={salesData} timeRange={timeRange} setTimeRange={setTimeRange} />
                        </div>
                        <div className="w-full md:w-1/2">
                            <LatestPurchasesList purchases={latestPurchases} />
                        </div>
                    </div>
                );
            case 'Inventory':
                return <InventoryChart inventoryData={inventoryData} />;
            case 'Customers':
                return <CustomerTable customerData={customerData} />;
            case 'Top_Products':
                return <TopProductsChart topProductsData={topProductsData} />;
            default:
                return null;
        }
    };

    return (
        <div className="flex bg-white">
            <Sidebar />
            <div className="flex-1 p-8 transition-all duration-300">
                <h1 className="text-5xl font-semibold my-10">Reports & Analytics</h1>
                <div className="flex space-x-4 mb-10">
                    {['Overview', 'Sales', 'Inventory', 'Customers', 'Top_Products'].map((tab) => (
                        <button
                            key={tab}
                            className={`font-medium ${selectedTab === tab ? 'text-blue-600' : 'text-gray-400'}`}
                            onClick={() => setSelectedTab(tab)}
                        >
                            {tab.replace('_', ' ')}
                        </button>
                    ))}
                </div>
                {renderTabContent()}
            </div>
        </div>
    );
};

const StatCard = ({ title, value, subtitle }) => (
    <div className="bg-white p-4">
        <h3 className="text-sm text-gray-500 mb-4 flex items-center">
            {title}
            <span className="ml-1 text-gray-400 cursor-help" title="Info about this metric">ⓘ</span>
        </h3>
        <p className="text-3xl font-bold mb-2">{value}</p>
        {subtitle && <p className="text-xs text-gray-500">{subtitle}</p>}
    </div>
);

const SalesChart = ({ salesData, timeRange, setTimeRange }) => (
    <div className="bg-white pt-10">
        <div className="flex justify-between items-center mb-12">
            <h2 className="text-xl font-bold">Daily Sales</h2>
            <select
                className="border p-2 rounded"
                value={timeRange}
                onChange={(e) => setTimeRange(e.target.value)}
            >
                <option>Last 7 days</option>
                <option>Last 30 days</option>
                <option>Last 90 days</option>
            </select>
        </div>
        <ResponsiveContainer width="100%" height={400} className={`pl-6`}>
            <LineChart data={salesData}>
                <CartesianGrid horizontal={true} vertical={false} stroke="#e0e0e0" />
                <XAxis
                    dataKey="formattedDate"
                    axisLine={false}
                    tickLine={false}
                    tick={{ fill: '#888', fontSize: 12 }}
                />
                <YAxis
                    axisLine={false}
                    tickLine={false}
                    tick={{ fill: '#888', fontSize: 12 }}
                />
                <Tooltip />
                <Line
                    type="monotone"
                    dataKey="sales"
                    stroke="#8884d8"
                    strokeWidth={3}
                    dot={false}
                    activeDot={{ r: 7 }}
                    animationDuration={500}
                    animationBegin={0}
                />
            </LineChart>
        </ResponsiveContainer>
    </div>
);


const LatestPurchasesList = ({ purchases }) => (
    <div className="bg-white pt-10">
        <h2 className="text-xl font-bold mb-4 mt-3">Latest Purchases</h2>
        <ul className="divide-y divide-gray-200 mb-4">
            {purchases.map((purchase) => (
                <li key={purchase.id} className="py-3">
                    <div className="flex justify-between">
                        <span className="font-medium">{purchase.item}</span>
                        <span className="text-gray-600">${purchase.price.toFixed(2)}</span>
                    </div>
                    <div className="text-sm text-gray-500">{formatDate(purchase.date)}</div>
                </li>
            ))}
        </ul>
        <div className="flex justify-end">
            <a href="#" className="text-blue-600 hover:text-blue-800 text-sm font-medium">
                Show More
            </a>
        </div>
    </div>
);

const InventoryChart = ({ inventoryData }) => (
    <>
        <h2 className="text-xl font-bold mb-6">Inventory Status</h2>
        <ResponsiveContainer width="100%" height={400}>
            <BarChart data={inventoryData}>
                <XAxis dataKey="category" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="inStock" fill="#8884d8" name="In Stock" />
                <Bar dataKey="lowStock" fill="#82ca9d" name="Low Stock" />
            </BarChart>
        </ResponsiveContainer>
    </>
);

const CustomerTable = ({ customerData }) => (
    <>
        <h2 className="text-xl font-bold mb-6">Customer Overview</h2>
        <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
                <thead className="bg-gray-100">
                    <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Purchases</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Purchase</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                    {customerData.map((customer) => (
                        <tr key={customer.id}>
                            <td className="px-6 py-4 whitespace-nowrap">{customer.id}</td>
                            <td className="px-6 py-4 whitespace-nowrap">{customer.name}</td>
                            <td className="px-6 py-4 whitespace-nowrap">${customer.totalPurchases}</td>
                            <td className="px-6 py-4 whitespace-nowrap">{customer.lastPurchase}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </>
);

const TopProductsChart = ({ topProductsData }) => (
    <>
        <h2 className="text-xl font-bold mb-6">Top Products</h2>
        <ResponsiveContainer width="100%" height={400}>
            <BarChart data={topProductsData} layout="vertical">
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" />
                <Tooltip />
                <Bar dataKey="sales" fill="#8884d8" />
            </BarChart>
        </ResponsiveContainer>
    </>
);

export default Reports;